import React from 'react';
import {Link} from "react-router-dom";

function Header(props)
{
return (
<>
<div className="header header-light dark-text">
    <div className="container">
      <nav id="navigation" className="navigation navigation-landscape">
        <div className="nav-header">
          <a className="nav-brand" href="#">
            <img src="assets/images/yea-logo.png" className="logo" alt="" />
          </a>
          <div className="nav-toggle"></div>
          <div className="mobile_nav">
            <ul>
              <li>
                <a href="#" className="theme-cl fs-lg">
                  <i className="lni lni-user"></i>
                </a>
              </li>
              <li>
                <a href="#" className="crs_yuo12 w-auto text-white theme-bg">
                  <span className="embos_45">
                    <i className="fas fa-plus me-2"></i>Add Listing </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="nav-menus-wrapper" >
          <ul className="nav-menu">
            <li className="active">
                <Link to="/">Home</Link> 
            </li>
            <li>
                <Link to="/about-us">About Us</Link> 
            </li>
            <li>
                <Link to="/businesses">Businesses</Link> 
            </li>
            <li>
                <Link to="/memebers">Memebers</Link> 
            </li><li>
                <Link to="/events">Events</Link> 
            </li>
            <li>
                <Link to="/news-updates">News & Updates</Link> 
            </li>
            <li>
                <Link to="/contact-us">Contact Us</Link> 
            </li>

          </ul>
          <ul className="nav-menu nav-menu-social align-to-right">           
            <li className="add-listing">
                <Link to="/"><i className="fas fa-plus me-2"></i> My Account</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>

   </>
);
}

export default Header;