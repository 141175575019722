function AboutUs()
{
return (
<>

<section className="about-bg bg-cover" style={{background:'url(assets/img/about.jpg) no-repeat'}}>
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-8 col-md-11 col-sm-12">
							<div className="abt-caption">
								<div className="abt-caption-head">
									<h1>Smart team alwasy create better thing and better solutions.</h1>
									<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</h6>
									<div className="abt-bt-info"><a href="javascript:void(0);" className="btn ft-medium theme-cl bg-white rounded">Get Started<i className="fas fa-arrow-right ms-2"></i></a></div>
									<div className="position-relative row">
										<div className="col-lg-4 col-md-4 col-4">
											<h3 className="ft-bold text-sky mb-0"><span className="count">07</span>+</h3>
											<p className="ft-medium text-light">Business Listing</p>
										</div>
										<div className="col-lg-4 col-md-4 col-4">
											<h3 className="ft-bold text-warning mb-0"><span className="count">06</span>k+</h3>
											<p className="ft-medium text-light">Popular Authors</p>
										</div>
										<div className="col-lg-4 col-md-4 col-4">
											<h3 className="ft-bold text-danger mb-0"><span className="count">200</span>+</h3>
											<p className="ft-medium text-light">Countries</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		
			<section className="space min">
				<div className="container">
					
					<div className="row justify-content-center">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div className="sec_title position-relative text-center mb-5">
								<h6 className="mb-0 theme-cl">Working Process</h6>
								<h2 className="ft-bold">How It Working</h2>
							</div>
						</div>
					</div>
				
					<div className="row align-items-center">
						
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
							<div className="wrk-pro-box first">
								<div className="wrk-pro-box-icon"><i className="ti-map-alt text-success"></i></div>
								<div className="wrk-pro-box-caption">
									<h4>Find Interesting Place</h4>
									<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti</p>
								</div>
							</div>
						</div>
						
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
							<div className="wrk-pro-box sec">
								<div className="wrk-pro-box-icon"><i className="ti-user theme-cl"></i></div>
								<div className="wrk-pro-box-caption">
									<h4>Contact A Few Owners</h4>
									<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti</p>
								</div>
							</div>
						</div>
						
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
							<div className="wrk-pro-box thrd">
								<div className="wrk-pro-box-icon"><i className="ti-shield text-sky"></i></div>
								<div className="wrk-pro-box-caption">
									<h4>Make A Reservation</h4>
									<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti</p>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</section>
			
			<section className="space min gray">
				<div className="container">
				
					<div className="row justify-content-center">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div className="sec_title position-relative text-center mb-5">
								<h6 className="mb-0 theme-cl">Our Team</h6>
								<h2 className="ft-bold">Goodup Expert team</h2>
							</div>
						</div>
					</div>
					
					<div className="row justify-content-center">
								
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-1.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">James R. Smith</a></h4>
									<div className="Goodup-location">Project Manager</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-2.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Howard L. Gallegos</a></h4>
									<div className="Goodup-location">Team Leader</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag popular">Popular</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-3.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Patricia C. Foshee</a></h4>
									<div className="Goodup-location">UI/UX Designer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-4.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Helen A. Robbins</a></h4>
									<div className="Goodup-location">Web Developer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-5.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Andrew D. Taylor</a></h4>
									<div className="Goodup-location">Content Writter</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-6.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Larry J. Mapes</a></h4>
									<div className="Goodup-location">Web Expert</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag popular">Popular</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-7.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Loretta G. Wood</a></h4>
									<div className="Goodup-location">Product Designer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-8.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Javier M. Brookins</a></h4>
									<div className="Goodup-location">Sales Manager</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
							
					</div>
					
				</div>
			</section>
			
			<section className="space min">
				<div className="container">
					
					<div className="row justify-content-center">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div className="sec_title position-relative text-center mb-4">
								<h6 className="theme-cl mb-0">Latest News</h6>
								<h2 className="ft-bold">Pickup New Updates</h2>
							</div>
						</div>
					</div>
					
					<div className="row justify-content-center">
						
						
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
							<div className="gup_blg_grid_box">
								<div className="gup_blg_grid_thumb">
									<a href="blog-detail.html"><img src="assets/img/b-4.jpg" className="img-fluid" alt="" /></a>
								</div>
								<div className="gup_blg_grid_caption">
									<div className="blg_tag"><span>Marketing</span></div>
									<div className="blg_title"><h4><a href="blog-detail.html">What Is a VPN and How Does It Work?</a></h4></div>
									<div className="blg_desc"><p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum</p></div>
								</div>
								<div className="crs_grid_foot">
									<div className="crs_flex d-flex align-items-center justify-content-between br-top px-3 py-2">
										<div className="crs_fl_first">
											<div className="crs_tutor">
												<div className="crs_tutor_thumb"><a href="javascript:void(0);"><img src="assets/img/team-2.jpg" className="img-fluid circle" width="35" alt="" /></a></div>
											</div>
										</div>
										<div className="crs_fl_last">
											<div className="foot_list_info">
												<ul>
													<li><div className="elsio_ic"><i className="fa fa-eye text-success"></i></div><div className="elsio_tx">10k Views</div></li>
													<li><div className="elsio_ic"><i className="fa fa-clock text-warning"></i></div><div className="elsio_tx">10 July 2021</div></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
							<div className="gup_blg_grid_box">
								<div className="gup_blg_grid_thumb">
									<a href="blog-detail.html"><img src="assets/img/b-5.jpg" className="img-fluid" alt="" /></a>
								</div>
								<div className="gup_blg_grid_caption">
									<div className="blg_tag"><span>Business</span></div>
									<div className="blg_title"><h4><a href="blog-detail.html">What Is Ransomware: The Ultimate Guide?</a></h4></div>
									<div className="blg_desc"><p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum</p></div>
								</div>
								<div className="crs_grid_foot">
									<div className="crs_flex d-flex align-items-center justify-content-between br-top px-3 py-2">
										<div className="crs_fl_first">
											<div className="crs_tutor">
												<div className="crs_tutor_thumb"><a href="javascript:void(0);"><img src="assets/img/team-3.jpg" className="img-fluid circle" width="35" alt="" /></a></div>
											</div>
										</div>
										<div className="crs_fl_last">
											<div className="foot_list_info">
												<ul>
													<li><div className="elsio_ic"><i className="fa fa-eye text-success"></i></div><div className="elsio_tx">10k Views</div></li>
													<li><div className="elsio_ic"><i className="fa fa-clock text-warning"></i></div><div className="elsio_tx">10 July 2021</div></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
							<div className="gup_blg_grid_box">
								<div className="gup_blg_grid_thumb">
									<a href="blog-detail.html"><img src="assets/img/b-6.jpg" className="img-fluid" alt="" /></a>
								</div>
								<div className="gup_blg_grid_caption">
									<div className="blg_tag"><span>Accounting</span></div>
									<div className="blg_title"><h4><a href="blog-detail.html">Can iPads Get Viruses? What You Need</a></h4></div>
									<div className="blg_desc"><p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum</p></div>
								</div>
								<div className="crs_grid_foot">
									<div className="crs_flex d-flex align-items-center justify-content-between br-top px-3 py-2">
										<div className="crs_fl_first">
											<div className="crs_tutor">
												<div className="crs_tutor_thumb"><a href="javascript:void(0);"><img src="assets/img/team-5.jpg" className="img-fluid circle" width="35" alt="" /></a></div>
											</div>
										</div>
										<div className="crs_fl_last">
											<div className="foot_list_info">
												<ul>
													<li><div className="elsio_ic"><i className="fa fa-eye text-success"></i></div><div className="elsio_tx">10k Views</div></li>
													<li><div className="elsio_ic"><i className="fa fa-clock text-warning"></i></div><div className="elsio_tx">10 July 2021</div></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
			</section>
			
    

   </>
);
}

export default AboutUs;