function Members()
{
return (
<>

<section className="space min gray">
				<div className="container">
				
					<div className="row justify-content-center">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div className="sec_title position-relative text-center mb-5">
								<h6 className="mb-0 theme-cl">Our Team</h6>
								<h2 className="ft-bold">Goodup Expert team</h2>
							</div>
						</div>
					</div>
					
					<div className="row justify-content-center">
								
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-1.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">James R. Smith</a></h4>
									<div className="Goodup-location">Project Manager</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-2.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Howard L. Gallegos</a></h4>
									<div className="Goodup-location">Team Leader</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag popular">Popular</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-3.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Patricia C. Foshee</a></h4>
									<div className="Goodup-location">UI/UX Designer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-4.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Helen A. Robbins</a></h4>
									<div className="Goodup-location">Web Developer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-5.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Andrew D. Taylor</a></h4>
									<div className="Goodup-location">Content Writter</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-6.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Larry J. Mapes</a></h4>
									<div className="Goodup-location">Web Expert</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag popular">Popular</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-7.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Loretta G. Wood</a></h4>
									<div className="Goodup-location">Product Designer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="author-detail.html"><img src="assets/img/t-8.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="author-detail.html">Javier M. Brookins</a></h4>
									<div className="Goodup-location">Sales Manager</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
							
					</div>
					
				</div>
			</section>

   </>
);
}

export default Members;