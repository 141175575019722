function ContactUs()
{
return (
<>

<section class="gray">
				<div class="container">
				
					<div class="row justify-content-center mb-5">
						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div class="sec_title position-relative text-center">
								<h2 class="off_title">Contact Us</h2>
							</div>
						</div>
					</div>
					
					<div class="row align-items-start justify-content-center">
						
						<div class="col-xl-10 col-lg-11 col-md-12 col-sm-12">
							<form class="row submit-form py-4 px-3 rounded bg-white mb-4">
									
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label class="small text-dark ft-medium">Your Name *</label>
										<input type="text" class="form-control" value="Your Name" />
									</div>
								</div>
								
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label class="small text-dark ft-medium">Your Email *</label>
										<input type="text" class="form-control" value="Your Email" />
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label class="small text-dark ft-medium">Mobile</label>
										<input type="text" class="form-control" value="+91 1256 548 7542" />
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label class="small text-dark ft-medium">Subject</label>
										<input type="text" class="form-control" value="Type Your Subject" />
									</div>
								</div>
								
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label class="small text-dark ft-medium">Message</label>
										<textarea class="form-control ht-80">Your Message...</textarea>
									</div>
								</div>
								
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<button type="button" class="btn theme-bg text-light">Send Message</button>
									</div>
								</div>
								
							</form>
						</div>
						
						<div class="col-xl-10 col-lg-11 col-md-12 col-sm-12">
							<div class="row justify-content-center">
								<div class="col-xl-4 col-lg-4 col-md-12">
									<div class="bg-white rounded p-3 mb-2">
										<h4 class="ft-medium mb-3 theme-cl">Address info:</h4>
										<p>1354 Green Street Nashville Drive Dodge City,<br /> KS 67801 United States</p>
									</div>
								</div>
							
								<div class="col-xl-4 col-lg-4 col-md-12">
									<div class="bg-white rounded p-3 mb-2">
										<h4 class="ft-medium mb-3 theme-cl">Drop A Mail:</h4>
										<p>Drop mail we will contact you within 24 hours.</p>
										<p class="lh-1 text-dark">yea@gmail.com</p>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</section>

   </>
);
}

export default ContactUs;