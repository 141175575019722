import React from 'react';
import { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Businesses from './components/Businesses';
import Memebers from './components/Memebers';
import Events from './components/Events';
import News from './components/News';
import ContactUs from './components/ContactUs';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';

import Layout from './Layout';


function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/jquery.min.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/popper.min.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/bootstrap.min.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/slick.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/jquery.magnific-popup.min.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/dropzone.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/counterup.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/lightbox.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/moment.min.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/daterangepicker.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);



  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/jQuery.style.switcher.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  
  return (
    
    <div className="App">
      <div id="main-wrapper">
      
      <BrowserRouter>
      <Header />
       <Routes>
         <Route element={<Layout />} >
           <Route path="/" element={<Home />} />
           <Route path="/about-us" element={<AboutUs />} />
           <Route path="/businesses" element={<Businesses />} />
           <Route path="/memebers" element={<Memebers />} />
           <Route path="/events" element={<Events />} />
           <Route path="/news-updates" element={<News />} />
           <Route path="/contact-us" element={<ContactUs />} />
           <Route path="/signin" element={<SignIn />} />
           <Route path="/signup" element={<SignUp />} />
         </Route>
       </Routes>
       <Footer />
     </BrowserRouter>
      
      <a id="tops-button" className="top-scroll" title="Back to top" href="#">
        <i className="ti-arrow-up"></i>
      </a>
      </div>
    </div>
  );
}

export default App;
