function Home()
{
return (
<>


  
  <div className="clearfix"></div>
  <section className="home-banner margin-bottom-0" style={{background:"#00599d url(assets/img/banner-6.jpg) no-repeat"}} data-overlay="5">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12">
							
							<div className="banner_caption text-center mb-5">
								<h1 className="banner_title ft-bold mb-1 text-white">Bringing your Innovative ideas to tangible reality.</h1>
								<p className="fs-md ft-medium text-white">Explore wonderful place to stay, salon, shoping, massage or visit local areas.</p>
							</div>
							
							<div className="Goodup-top-cates">
								<ul>
									<li><a href="#" className="Goodup-top-cat-box"><div className="Goodup-tp-ico"><i className="fas fa-stethoscope"></i></div><div className="Goodup-tp-title"><h5 className="text-white">Hospital</h5></div></a></li>
									<li><a href="#" className="Goodup-top-cat-box"><div className="Goodup-tp-ico"><i className="fas fa-shopping-basket"></i></div><div className="Goodup-tp-title"><h5 className="text-white">Shoppings</h5></div></a></li>
									<li><a href="#" className="Goodup-top-cat-box"><div className="Goodup-tp-ico"><i className="fas fa-basketball-ball"></i></div><div className="Goodup-tp-title"><h5 className="text-white">Active Life</h5></div></a></li>
									<li><a href="#" className="Goodup-top-cat-box"><div className="Goodup-tp-ico"><i className="fas fa-utensils"></i></div><div className="Goodup-tp-title"><h5 className="text-white">Restaurants</h5></div></a></li>
									<li><a href="#" className="Goodup-top-cat-box"><div className="Goodup-tp-ico"><i className="fas fa-car-alt"></i></div><div className="Goodup-tp-title"><h5 className="text-white">Automotive</h5></div></a></li>
									<li><a href="#" className="Goodup-top-cat-box"><div className="Goodup-tp-ico"><i className="fas fa-pencil-ruler"></i></div><div className="Goodup-tp-title"><h5 className="text-white">Art & Design</h5></div></a></li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>
			</section>
			
			<section className="p-0">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-11 col-lg-12 col-md-12 col-12">
							
							<div className="Goodup-search-shadow">
								
								<ul className="nav nav-tabs search-tab mb-3" id="myTab" role="tablist">
									<li className="nav-item" role="presentation">
										<button className="nav-link active" id="placesseach-tab" data-bs-toggle="tab" data-bs-target="#placesseach" type="button" role="tab" aria-controls="placesseach" aria-selected="true">Businesses</button>
									</li>
									<li className="nav-item" role="presentation">
										<button className="nav-link" id="carseach-tab" data-bs-toggle="tab" data-bs-target="#carseach" type="button" role="tab" aria-controls="carseach" aria-selected="false">Memebers</button>
									</li>
									<li className="nav-item" role="presentation">
										<button className="nav-link" id="realseach-tab" data-bs-toggle="tab" data-bs-target="#realseach" type="button" role="tab" aria-controls="realseach" aria-selected="false">Events</button>
									</li>
									
								</ul>
								<div className="tab-content" id="myTabContent">
									<div className="tab-pane fade show active" id="placesseach" role="tabpanel" aria-labelledby="placesseach-tab">
										<form className="main-search-wrap fl-wrap">
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-keyboard"></i></span>
												<input type="text" className="form-control radius" placeholder="What are you looking for?" />
											</div>
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-map-marker"></i></span>
												<input type="text" className="form-control" placeholder="San Francisco, CA" />
											</div>
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-briefcase"></i></span>
												<select className="form-control">
													<option value="">Choose Category</option>
													<option value="1">Restaurants</option>
													<option value="2">Information Technology</option>
													<option value="3">Hotel Booking</option>
													<option value="4">Cafe & Night Bars</option>
													<option value="5">Healthcare/Pharma</option>
													<option value="6">Wedding & Marriage</option>
													<option value="7">Finance/Insurance</option>
												</select>
											</div>
											<div className="main-search-button">
												<button className="btn full-width theme-bg text-white" type="button">Search</button>
											</div>
										</form>
									</div>
									<div className="tab-pane fade" id="carseach" role="tabpanel" aria-labelledby="carseach-tab">
										<form className="main-search-wrap fl-wrap">
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-keyboard"></i></span>
												<input type="text" className="form-control radius" placeholder="car type, car name" />
											</div>
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-map-marker"></i></span>
												<input type="text" className="form-control" placeholder="Price" />
											</div>
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-briefcase"></i></span>
												<select className="form-control">
													<option value="">Choose Car Brand</option>
													<option value="1">Tata Nexon</option>
													<option value="2">BMW Brand</option>
													<option value="3">Maruti Suzuki</option>
													<option value="4">Hundai Company</option>
													<option value="5">Kia Company</option>
													<option value="6">Mahindra Company</option>
												</select>
											</div>
											<div className="main-search-button">
												<button className="btn full-width theme-bg text-white" type="button">Search</button>
											</div>
										</form>
									</div>
									<div className="tab-pane fade" id="realseach" role="tabpanel" aria-labelledby="realseach-tab">
										<form className="main-search-wrap fl-wrap">
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-keyboard"></i></span>
												<input type="text" className="form-control radius" placeholder="Job Title, Keyword or Company" />
											</div>
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-map-marker"></i></span>
												<input type="text" className="form-control" placeholder="Location or Zip Code" />
											</div>
											<div className="main-search-item">
												<span className="search-tag"><i className="lni lni-briefcase"></i></span>
												<select className="form-control">
													<option value="">Property Type</option>
													<option value="1">Homes & Villa</option>
													<option value="2">Apartment</option>
													<option value="3">Condos</option>
													<option value="4">Space & Office</option>
													<option value="5">Town Hall</option>
													<option value="6">Buildings</option>
												</select>
											</div>
											<div className="main-search-button">
												<button className="btn full-width theme-bg text-white" type="button">Search</button>
											</div>
										</form>
									</div>
								
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>

            <section className="space min">
				<div className="container">
					
					<div className="row justify-content-center">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div className="sec_title position-relative text-center mb-5">
								<h6 className="mb-0 theme-cl">Working Process</h6>
								<h2 className="ft-bold">How It Working</h2>
							</div>
						</div>
					</div>
				
					<div className="row align-items-center">
						
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
							<div className="wrk-pro-box first">
								<div className="wrk-pro-box-icon"><i className="ti-map-alt text-success"></i></div>
								<div className="wrk-pro-box-caption">
									<h4>Meet</h4>
									<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti</p>
								</div>
							</div>
						</div>
						
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
							<div className="wrk-pro-box sec">
								<div className="wrk-pro-box-icon"><i className="ti-user theme-cl"></i></div>
								<div className="wrk-pro-box-caption">
									<h4>Connect</h4>
									<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti</p>
								</div>
							</div>
						</div>
						
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
							<div className="wrk-pro-box thrd">
								<div className="wrk-pro-box-icon"><i className="ti-shield text-sky"></i></div>
								<div className="wrk-pro-box-caption">
									<h4>Grow</h4>
									<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti</p>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</section>

  <section className="space min gray">
				<div className="container">
				
					<div className="row justify-content-center d-flex">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div className="sec_title position-relative text-center mb-5">
								<h6 className="mb-0 theme-cl">Popular Categories</h6>
								<h2 className="ft-bold">Browse Top Categories</h2>
							</div>
						</div>
					</div>
					
					
					<div className="row align-items-center d-flex">
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">07 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-stethoscope"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Dentists</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">17 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-building"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">IT &amp; Banking</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">19 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-shopping-basket"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Shoppings</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">32 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-screwdriver"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Home Services</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">27 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-basketball-ball"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Active Life</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">26 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-utensils"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Restaurants</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">popular</div>
									<div className="Goodup-catg-icon"><i className="fas fa-book-open"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Education</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">24 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-house-damage"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Real Estate</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">18 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-wine-glass"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Event Palnning</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">06 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-car-alt"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Automotive</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">08 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-pencil-ruler"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Art &amp; Design</h4>
										
									</div>
								</a>
							</div>
						</div>
						
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4">
							<div className="cats-wrap text-center">
								<a href="#" className="Goodup-catg-wrap">
									<div className="Goodup-catg-city">05 Cities</div>
									<div className="Goodup-catg-icon"><i className="fas fa-plane"></i></div>
									<div className="Goodup-catg-caption">
										<h4 className="fs-md mb-0 ft-medium m-catrio">Hotel & Travel</h4>
										
									</div>
								</a>
							</div>
						</div>
						
					</div>
					
					
				</div>
			</section>
  
  <section className="space min">
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="sec_title position-relative text-center mb-5">
            <h2 className="ft-bold theme-cl">Businesses</h2>
          </div>
        </div>
      </div>
      
      <div className="row justify-content-center">
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status open me-2">Open</div>
                <div className="Goodup-featured-tag">Featured</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-1.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-1.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Wedding</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">Rajwara Marriage Home <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>Liverpool, London UK
                  </div>
                 
                </div>
              </div>
         
            </div>
          </div>
        </div>
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status close me-2">Closed</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-2.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-2.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Sports</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">Decathlon Sport House <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>San Denever, USA
                  </div>
                 
                </div>
              </div>
          
          
            </div>
          </div>
        </div>
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status open me-2">Open</div>
                <div className="Goodup-featured-tag">Featured</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-3.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-3.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Hotels</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">The Gold Hotel Lalit <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>California, USA
                  </div>
                
                </div>
              </div>
          
            </div>
          </div>
        </div>
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status open me-2">open</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-4.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-4.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Zym & Health</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">Fitness Revolution Gym <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>New Wshington, UK
                  </div>
                
                </div>
              </div>
          
            </div>
          </div>
        </div>
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status close me-2">Closed</div>
                <div className="Goodup-featured-tag">Featured</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-5.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-5.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Beauty & Makeup</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">Pretty Woman Smart Batra <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>Shiv Narkilla, Brazil
                  </div>
                 
                </div>
              </div>
           
            </div>
          </div>
        </div>
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status open me-2">Open</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-6.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-6.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Night Party</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">The Sartaj Blue Night <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>San Francisco, USA
                  </div>
              
                </div>
              </div>
            
            </div>
          </div>
        </div>
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status close me-2">Closed</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-7.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-7.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Cafe & Bar</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">Pizza Delight Cafe <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>Liverpool, London
                  </div>
            
                </div>
              </div>
           
            </div>
          </div>
        </div>
        
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6">
          <div className="Goodup-grid-wrap">
            <div className="Goodup-grid-upper">
              <div className="Goodup-bookmark-btn">
                <button type="button">
                  <i className="lni lni-heart-filled position-absolute"></i>
                </button>
              </div>
              <div className="Goodup-pos ab-left">
                <div className="Goodup-status open me-2">Open</div>
                <div className="Goodup-featured-tag">Featured</div>
              </div>
              <div className="Goodup-grid-thumb">
                <a href="#" className="d-block text-center m-auto">
                  <img src="assets/img/listing/l-8.jpg" className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="Goodup-grid-fl-wrap">
              <div className="Goodup-caption px-2 py-2">
                <div className="Goodup-author">
                  <a href="#">
                    <img src="assets/img/t-8.png" className="img-fluid circle" alt="" />
                  </a>
                </div>
                <div className="Goodup-cates">
                  <a href="#">Shopping Mall</a>
                </div>
                <h4 className="mb-0 ft-medium medium">
                  <a href="#" className="text-dark fs-md">The Great Allante Shop <span className="verified-badge">
                      <i className="fas fa-check-circle"></i>
                    </span>
                  </a>
                </h4>
                <div className="Goodup-middle-caption mt-3">
                  <div className="Goodup-location">
                    <i className="fas fa-map-marker-alt"></i>Old california, USA
                  </div>
               
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>
  
  <section className="space min gray">
				<div className="container">
				
					<div className="row justify-content-center">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div className="sec_title position-relative text-center mb-5">
								<h6 className="mb-0 theme-cl">Our Team</h6>
								<h2 className="ft-bold">Goodup Expert team</h2>
							</div>
						</div>
					</div>
					
					<div className="row justify-content-center">
								
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-1.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">James R. Smith</a></h4>
									<div className="Goodup-location">Project Manager</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-2.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">Howard L. Gallegos</a></h4>
									<div className="Goodup-location">Team Leader</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag popular">Popular</div>
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-3.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">Patricia C. Foshee</a></h4>
									<div className="Goodup-location">UI/UX Designer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-4.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">Helen A. Robbins</a></h4>
									<div className="Goodup-location">Web Developer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-5.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">Andrew D. Taylor</a></h4>
									<div className="Goodup-location">Content Writter</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-6.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">Larry J. Mapes</a></h4>
									<div className="Goodup-location">Web Expert</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag popular">Popular</div>
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-7.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">Loretta G. Wood</a></h4>
									<div className="Goodup-location">Product Designer</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
							<div className="Goodup-author-wrap">
								<div className="Goodup-author-tag new">New</div>
								<div className="Goodup-author-thumb"><a href="#"><img src="assets/img/t-8.png" className="img-fluid circle" alt="" /></a></div>
								<div className="Goodup-author-caption">
									<h4 className="fs-md mb-0 ft-medium m-catrio"><a href="#">Javier M. Brookins</a></h4>
									<div className="Goodup-location">Sales Manager</div>
								</div>
								<div className="Goodup-author-links">
									<ul className="Goodup-social colored">
										<li><a href="javascript:void(0);"><i className="lni lni-facebook-filled"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-linkedin-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-twitter-original"></i></a></li>
										<li><a href="javascript:void(0);"><i className="lni lni-instagram-original"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
							
					</div>
					
				</div>
			</section>
 
            <section class="space min">
				<div class="container">
					
					<div class="row justify-content-center">
						<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<div class="sec_title position-relative text-center mb-4">
								<h6 class="theme-cl mb-0">Latest News</h6>
								<h2 class="ft-bold">Pickup New Updates</h2>
							</div>
						</div>
					</div>
					
					<div class="row justify-content-center">
						
						
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
							<div class="gup_blg_grid_box">
								<div class="gup_blg_grid_thumb">
									<a href="#"><img src="assets/img/b-4.jpg" class="img-fluid" alt="" /></a>
								</div>
								<div class="gup_blg_grid_caption">
									<div class="blg_tag"><span>Marketing</span></div>
									<div class="blg_title"><h4><a href="#">What Is a VPN and How Does It Work?</a></h4></div>
									
								</div>
							
							</div>
						</div>
						
						
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
							<div class="gup_blg_grid_box">
								<div class="gup_blg_grid_thumb">
									<a href="#"><img src="assets/img/b-5.jpg" class="img-fluid" alt="" /></a>
								</div>
								<div class="gup_blg_grid_caption">
									<div class="blg_tag"><span>Business</span></div>
									<div class="blg_title"><h4><a href="#">What Is Ransomware: The Ultimate Guide?</a></h4></div>
									
								</div>
								
							</div>
						</div>
						
						
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
							<div class="gup_blg_grid_box">
								<div class="gup_blg_grid_thumb">
									<a href="#"><img src="assets/img/b-6.jpg" class="img-fluid" alt="" /></a>
								</div>
								<div class="gup_blg_grid_caption">
									<div class="blg_tag"><span>Accounting</span></div>
									<div class="blg_title"><h4><a href="#">Can iPads Get Viruses? What You Need</a></h4></div>
									
								</div>
								
							</div>
						</div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
							<div class="gup_blg_grid_box">
								<div class="gup_blg_grid_thumb">
									<a href="#"><img src="assets/img/b-6.jpg" class="img-fluid" alt="" /></a>
								</div>
								<div class="gup_blg_grid_caption">
									<div class="blg_tag"><span>Accounting</span></div>
									<div class="blg_title"><h4><a href="#">Can iPads Get Viruses? What You Need</a></h4></div>
									
								</div>
								
							</div>
						</div>
						
					</div>
					
				</div>
			</section>
 

    
   </>
);
}

export default Home;